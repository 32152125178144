import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import Account from "./Account";
import { beginFetchAccountInformation } from "../../sagas/account";

function mapState(state: any) {
  return {
    emailAddress: state.login.emailAddress,
    accountLoading: state.account.loading,
    userType: state.login.userType,
    siteId: state.account.siteId,
    userLevel: state.account.userLevel,
    username: state.account.username
  };
}

function mapDispatch(dispatch: Dispatch) {
  return {
    fetchAccountInformation: bindActionCreators(
      beginFetchAccountInformation,
      dispatch
    )
  };
}

export default connect(
  mapState,
  mapDispatch
)(Account);
