import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import 'antd/dist/antd.css';
import Root from './root';
import configureStore, { history } from './configureStore';
import * as serviceWorker from './serviceWorker';
import { getSessionStorage, setSessionStorage } from './sagas/utils';
import { updateToken } from './components/Home/HomeContainer';
const store = configureStore();

axios.interceptors.response.use(
  // @ts-ignore
  response => response,
  error => {
    const token = getSessionStorage('token');
    const expires = getSessionStorage('expiresIn');
    const userType = getSessionStorage('userType');
    const email = getSessionStorage('email');
    const refresh_token = getSessionStorage('refreshToken');
    const originalRequest = error.config;

    if (
      token &&
      expires &&
      parseInt(expires, 10) < Date.now() &&
      !originalRequest._retry &&
      error.response.status === 401
    ) {
      originalRequest._retry = true;

      if (userType === 'privileged_user') {
        return axios
          .post(
            `${process.env.REACT_APP_WebApiBaseUrl}/privileged-users/refresh`,
            {
              email,
              refresh_token,
            },
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: !!token && `Bearer ${token}`,
              },
            },
          )
          .then(res => {
            store.dispatch(updateToken({ idToken: res.data.id_token }));
            setSessionStorage('expiresIn', (Date.now() + error.data.expires_in * 1000).toString());
            originalRequest.headers['Authorization'] = `Bearer ${res.data.id_token}`;
            return axios.request(originalRequest);
          })
          .catch(err => {
            console.error(err);
          });
      } else {
        return axios
          .post(
            `${process.env.REACT_APP_WebApiBaseUrl}/clinical-investigators/refresh`,
            {
              email,
              refresh_token,
            },
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: !!token && `Bearer ${token}`,
              },
            },
          )
          .then(res => {
            store.dispatch(updateToken({ idToken: res.data.id_token }));
            setSessionStorage('expiresIn', (Date.now() + error.data.expires_in * 1000).toString());
            originalRequest.headers['Authorization'] = `Bearer ${res.data.id_token}`;
            return axios.request(originalRequest);
          })
          .catch(err => {
            console.error(err);
          });
      }
    }
    return Promise.reject(error);
  },
);

ReactDOM.render(
  <Provider store={store}>
    <Root history={history} />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
