import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Route, Redirect } from 'react-router';
import { Button, Layout, Table, Icon, Input, DatePicker, notification } from 'antd';
import capitalize from 'lodash.capitalize';
import dayjs from 'dayjs';
import { isUserLoggedIn } from '../../routes';
import SessionDetail from '../SessionDetail/SessionDetailContainer';
import SessionStyles from './Sessions.module.css';
import DisclaimerFooter from '../DisclaimerFooter/DisclamerFooter';
import AvatarPageTitleWrapper from '../AvatarPageTitleWrapper/AvatarPageTitleWrapper';
import { getSessionStorage } from '../../sagas/utils';
import SessionDetailContainer from '../SessionDetail/SessionDetailContainer';

interface SessionData {
  SubjectID: number;
  SubjectStatus: string;
  ComplianceScore: number;
  clinicalSite: string;
  SessionID: string;
  RecruitmentDate: string;
  Country: string;
  downloadLink: string;
  SiteID: string;
  SessionDataPath: string;
  key: string;
}

const compareStrings = (a: string, b: string) => {
  if (a > b) return -1;
  if (a < b) return 1;
  return 0;
};

const { Content } = Layout;

interface SessionProps {
  fetchSessions: Function;
  userType: string;
  email: string;
  history: any;
  match: any;
  sessions: Array<SessionData>;
  sessionLoading: boolean;
}

const Sessions: React.FC<SessionProps> = ({
  fetchSessions,
  userType,
  email,
  history,
  match,
  sessions,
  sessionLoading,
}) => {
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const searchInput = useRef(null);
  const user: string = userType ? userType : getSessionStorage('userType');

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: Function;
      selectedKeys: Array<number>;
      confirm: Function;
      clearFilters: Function;
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e: any) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => {
            handleSearch(selectedKeys, confirm);
          }}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string, record: any) =>
      record[dataIndex] &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.trim().toLowerCase()),
    render: (text: string) => <div>{text}</div>,
  });

  const getDateColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: Function;
      selectedKeys: string;
      confirm: Function;
      clearFilters: Function;
    }) => (
      <div style={{ padding: 8 }}>
        <DatePicker
          onChange={(e: any) => {
            setSelectedKeys(e);
          }}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => {
            setSelectedKeys([dayjs(selectedKeys).format('YYYY-MM-DDTHH:MM:ss')]);
            confirm();
          }}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string, record: any) => {
      return dayjs(value).isSame(dayjs(record.RecruitmentDate), 'day');
    },
    render: (text: string) => <span>{text ? dayjs(text.split(' ').join('')).format('YYYY.MM.DD') : 'None'}</span>,
  });

  const handleSearch = (selectedKeys: any, confirm: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: Function) => {
    clearFilters();
    setSearchText('');
  };

  let columns = [
    {
      title: 'Subject ID',
      dataIndex: 'SubjectID',
      key: 'subjectID',
      sorter: (a: SessionData, b: SessionData): number => {
        if (a.SubjectID > b.SubjectID) {
          return -1;
        }
        if (a.SubjectID < b.SubjectID) {
          return 1;
        }
        return 0;
      },
      ...getColumnSearchProps('SubjectID'),
    },
    {
      title: 'Subject Status',
      dataIndex: 'SubjectStatus',
      key: 'status',
      // filterDropdown: (props: any) => <SubjectStatusFilter props={props} />,
      filterMultiple: false,
      filters: [
        { text: 'None', value: 'None' },
        { text: 'Active', value: 'Active' },
        { text: 'Completed', value: 'Completed' },
        { text: 'Recruited', value: 'Recruited' },
      ],
      onFilter: (text: string, record: SessionData) => {
        let status = record.SubjectStatus;
        if ((status === undefined || status === null) && text === 'None') return true;
        return status === text;
      },
      render: (text: string) => <span>{text ? capitalize(text) : 'None'}</span>,
    },
    {
      title: 'Compliance Score',
      dataIndex: 'ComplianceScore',
      key: 'complianceScore',
      filterMultiple: false,
      filters: [
        { text: '85% ~ 100%', value: 'GREEN' },
        { text: '65% ~ 84%', value: 'ORANGE' },
        { text: '0% ~ 64%', value: 'RED' },
        { text: 'None', value: 'None' },
      ],
      onFilter: (text: string, record: SessionData) => {
        let complianceScore = record.ComplianceScore;
        if (complianceScore == null) {
          if (text === 'None') return true;
          else return false;
        } else {
          if (complianceScore > 85 && text === 'GREEN') return true;
          else if (complianceScore <= 85 && complianceScore >= 65 && text === 'ORANGE') return true;
          else if (complianceScore < 65 && text === 'RED') return true;
          else return false;
        }
      },
      filtered: true,
      render: (text: number) => {
        let color: string = '#02B150'; // Green
        if (text <= 85 && text >= 65) {
          color = '#FFBD2E'; // Orange
        } else if (text < 65) {
          color = '#DC0D03'; // Red
        }
        return <span style={{ color: text ? color : '' }}>{text != null ? `${text} %` : 'None'}</span>;
      },
      sorter: (a: SessionData, b: SessionData): number => {
        let score1 = a.ComplianceScore != null ? a.ComplianceScore : -1;
        let score2 = b.ComplianceScore != null ? b.ComplianceScore : -1;
        if (score1 > score2) {
          return -1;
        }
        if (score1 < score2) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Session ID',
      dataIndex: 'SessionID',
      key: 'sessionID',
      sorter: (a: SessionData, b: SessionData): number => {
        return compareStrings(a.SessionID, b.SessionID);
      },
      ...getColumnSearchProps('SessionID'),
    },
    {
      title: 'Recruitment Date',
      dataIndex: 'RecruitmentDate',
      key: 'RecruitmentDate',
      render: (text: string) => {
        return <span>{text ? dayjs(text.split(' ').join('')).format('YYYY.MM.DD') : 'None'}</span>;
      },
      sorter: (a: SessionData, b: SessionData): any => {
        if (a.RecruitmentDate === null && b.RecruitmentDate === null) return 0;
        if (a.RecruitmentDate === null) return -1;
        if (b.RecruitmentDate === null) return 1;
        return compareStrings(a.RecruitmentDate, b.RecruitmentDate);
      },
      ...getDateColumnSearchProps('RecruitmentDate'),
    },
  ];

  const clinicalSite = {
    title: 'Clinical Site',
    dataIndex: 'SiteID',
    key: 'clinicalSite',
    filterMultiple: false,
    filters: undefined,
    onFilter: (text: string, record: SessionData) => {
      return text === record.SiteID;
    },
    sorter: (a: SessionData, b: SessionData): number => {
      return compareStrings(a.SiteID, b.SiteID);
    },
  };

  const country = {
    title: 'Country',
    dataIndex: 'Country',
    key: 'Country',
    filterMultiple: false,
    filters: undefined,
    onFilter: (text: string, record: SessionData) => {
      return text === record.Country;
    },
    sorter: (a: SessionData, b: SessionData): number => {
      return compareStrings(a.Country, b.Country);
    },
  };

  if (user === 'privileged_user') {
    // @ts-ignore
    columns = [...columns, clinicalSite, country];
    let sites = sessions.map((value: SessionData) => value.SiteID);
    let sitesSet = new Set(sites);
    let sitesFilter = new Array<{ text: string; value: string }>();
    sitesSet.forEach((site: string) => {
      sitesFilter.push({ text: site, value: site });
    });
    columns[5].filters = sitesFilter;

    let countries = sessions.map((value: SessionData) => value.Country);
    let countriesSet = new Set(countries);
    let countriesFilter = new Array<{ text: string; value: string }>();
    countriesSet.forEach((country: string) => {
      countriesFilter.push({ text: country, value: country });
    });
    columns[6].filters = countriesFilter;
  }

  useEffect(() => {
    fetchSessions({ email, user });
  }, [email, fetchSessions, user]);

  const rowSelection = {
    onSelect: (record: SessionData, selected: boolean, selectedRow: any) => {
      setSelectedRows(selectedRow);
    },
    onSelectAll: (selected: boolean, selectedRows: any, changeRows: any) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: SessionData) => ({
      disabled: record.SessionDataPath === null,
    }),
  };

  const downloadData = async () => {
    const token = getSessionStorage('token');
    const user = getSessionStorage('userType');
    try {
      const links: any = await axios({
        url:
          user === 'privileged_user'
            ? `${process.env.REACT_APP_WebApiBaseUrl}/privileged-users/sessions/download`
            : `${process.env.REACT_APP_WebApiBaseUrl}/clinical-investigators/sessions/download`,
        method: 'POST',
        data: JSON.stringify({
          SessionIDs: selectedRows.map((row: any) => row.SessionID),
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: !!token && `Bearer ${token}`,
        },
      });

      links.data &&
        links.data.SessionDataPaths.map(async (hostedUrl: any, index: number) => {
          // @ts-ignore
          const sessionID = selectedRows[index].SessionID;

          notification.open({
            key: sessionID,
            type: 'info',
            message: `Downloading Session ${sessionID}`,
            duration: 0,
            icon: <Icon type="sync" spin />,
          });
          const response = await axios({
            url: hostedUrl,
            method: 'GET',
            responseType: 'blob',
          });
          notification.open({
            key: sessionID,
            type: 'info',
            message: `Download Successful ${sessionID}`,
            icon: <Icon type="check-circle" style={{ color: '#34b2ea' }} />,
          });

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${sessionID}.zip`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      notification.open({
        type: 'error',
        message: 'Download Failed. Please try again.',
      });
    }
  };
  return (
    <React.Fragment>
      <Layout>
        <DisclaimerFooter className={SessionStyles.disclaimer} />
        <div className={SessionStyles.container}>
          <AvatarPageTitleWrapper email={email} pageTitle={'Download Data'} user={user} />
          <Content>
            <div className={SessionStyles.tableContainer}>
              <div className={SessionStyles.topHeader}>
                <h2>ALL SESSIONS</h2>
                <Button
                  type="primary"
                  onClick={downloadData}
                  disabled={selectedRows.length < 1}
                  className={SessionStyles.downloadButton}
                >
                  Download Data
                </Button>
              </div>
              <h4>{selectedRows.length} selected</h4>
              <Table
                loading={sessionLoading}
                size={'middle'}
                className={SessionStyles.table}
                columns={columns}
                indentSize={20}
                dataSource={sessions}
                rowSelection={rowSelection}
                onRow={record => ({
                  onClick: () => history.push(`${match.url}/${record.SessionID}`),
                })}
                pagination={{
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
              <Route
                exact
                path="/sessions/:sessionId"
                render={props =>
                  isUserLoggedIn() ? <SessionDetail match={props.match} history={props.history} /> : <Redirect to="/" />
                }
              />
            </div>
          </Content>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Sessions;
