import { handleActions } from 'redux-actions';
import { GET_SESSIONS_DETAILS, FINISH_GET_SESSIONS_DETAILS } from '../sagas/sessionDetail';

export const defaultState = {
  data: [],
  vitals: {},
  loading: false,
};

const reducer = handleActions(
  {
    GET_SESSIONS_DETAILS: (state: any, action: any) => ({
      ...state,
      loading: true,
    }),
    FINISH_GET_SESSIONS_DETAILS: (state: any, action: any) => ({
      loading: false,
      data: action.payload,
      vitals: action.payload.Vitals,
    }),
  },
  defaultState,
);

export default reducer;
