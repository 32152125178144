import { take, put, call } from "redux-saga/effects";
import { notification } from "antd";
import { createAction } from "redux-actions";
import sibelAPI from "../apis";
import { history } from "../configureStore";

export const BEGIN_FETCH_REGISTER_USER = "BEGIN_FETCH_REGISTER_USER";
export const FINISH_FETCH_REGISTER_USER = "FINISH_FETCH_REGISTER_USER";

export const beginFetchRegisterUser = createAction("BEGIN_FETCH_REGISTER_USER");
export const finishFetchRegisterUser = createAction(
  "FINISH_FETCH_REGISTER_USER"
);

export function* watchRegisterUser() {
  while (true) {
    const { payload } = yield take(BEGIN_FETCH_REGISTER_USER);
    yield call(registerUser, payload);
  }
}

export function* registerUser(payload: any) {
  try {
    const response = yield call(
      sibelAPI,
      "clinicalInvestigatorSignUp",
      payload
    );

    if (!response) {
      notification.open({
        type: "error",
        message: "The email already is associated with an account."
      });
      return;
    }
    yield put(finishFetchRegisterUser(response.data));
    yield history.push("/confirmation");
  } catch (error) {
    notification.open({
      type: "error",
      message: error.message
    });
  }
}
