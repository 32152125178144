import { take, put, call } from "redux-saga/effects";
import { notification } from "antd";
import { createAction } from "redux-actions";
import sibelAPI from "../apis";
import { history } from "../configureStore";

export const BEGIN_SECRET_RESET = "BEGIN_SECRET_RESET";
export const FINISH_SECRET_RESET = "FINISH_SECRET_RESET";

export const beginSecretReset = createAction("BEGIN_SECRET_RESET");
export const finishSecretReset = createAction("FINISH_SECRET_RESET");

export function* watchSecretReset() {
  while (true) {
    const { payload } = yield take(BEGIN_SECRET_RESET);
    yield call(secretReset, payload);
  }
}

export function* secretReset(payload: any) {
  try {
    const response = yield call(sibelAPI, "secretReset", payload);

    if (!response) {
      notification.open({
        type: "error",
        message: "The email already is associated with an account."
      });
      return;
    }
    yield put(finishSecretReset(response.data));
    notification.open({
      type: "success",
      message: "Successfully reset password!"
    });
  } catch (error) {
    notification.open({
      type: "error",
      message: error.message
    });
  }
}
