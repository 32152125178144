import React, { useState, useEffect } from "react";
import { Layout, Table, Button } from "antd";
import AdminStyles from "./Admin.module.css";
import PageTitle from "../PageTitle/PageTitle";
import DisclaimerFooter from "../DisclaimerFooter/DisclamerFooter";

const columns = [
  {
    title: "Email Address",
    dataIndex: "Username",
    key: "username"
  },
  {
    title: "Clinical Site ID",
    dataIndex: "Attributes.Value",
    key: "clinicalSiteID",
    render: (text: string) => <div>{text.toUpperCase()}</div>
  }
];

type ClinicalUsers = {
  Username: string;
  Attributes: {
    Name: string;
    Value: string;
  };
};

interface AdminProps {
  fetchAdminDashboardClinicalUsers: Function;
  clinicalUsers: Array<ClinicalUsers>;
  adminLoadingUsers: boolean;
  authorizeUsers: Function;
  setSelectedRows: Function;
  selectedRows: Array<ClinicalUsers>
}

const Admin: React.FC<AdminProps> = ({
  fetchAdminDashboardClinicalUsers,
  clinicalUsers,
  adminLoadingUsers,
  authorizeUsers,
  setSelectedRows,
  selectedRows
}) => {
  useEffect(() => {
    fetchAdminDashboardClinicalUsers();
  }, [fetchAdminDashboardClinicalUsers]);

  const rowSelection = {
    selectedRowKeys: selectedRows.map((v: any) => v.key),
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      selectedRows.map((row: any, index: number) => row.key = selectedRowKeys[index])
      setSelectedRows(selectedRows) 
    },
    onSelectAll: (selected: boolean, selectedRows: any, changeRows: any) => {
      setSelectedRows(selectedRows);
    },
  };

  const authorizeClinicalUsers = () => {
    let usernames = selectedRows.map((value: ClinicalUsers) => value.Username);
    authorizeUsers(usernames);
  };

  return (
    <React.Fragment>
      <Layout>
        <DisclaimerFooter className={AdminStyles.disclaimer} />
        <div className={AdminStyles.container}>
          <PageTitle title={"Admin Dashboard"} />
          <Table
            loading={adminLoadingUsers}
            className={AdminStyles.adminTable}
            size={"middle"}
            columns={columns}
            dataSource={clinicalUsers}
            indentSize={40}
            rowSelection={rowSelection}
          />
          <div className={AdminStyles.giveAccessContainer}>
            <Button
              type="primary"
              onClick={authorizeClinicalUsers}
              disabled={selectedRows.length < 1}
            >
              Give Access
            </Button>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Admin;
