import React from "react";
import PasswordConfirmationStyles from "./PasswordConfirmation.module.css";
import DisclaimerFooter from "../DisclaimerFooter/DisclamerFooter";
import Logo from "../../assets/Logo.svg";
import { Input, Icon, Button, Form } from "antd";

interface LoginProps {
  form: {
    getFieldsError: Function;
    getFieldDecorator: Function;
    validateFields: Function;
  };
  emailAddress: string;
  registerUser: Function;
}

type RegisterValues = {
  siteId: string;
  email: string;
};

function hasErrors(fieldsError: any) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const PasswordConfirmation: React.FC<LoginProps> = ({
  form: { getFieldsError, getFieldDecorator, validateFields },
  registerUser,
  emailAddress
}) => {
  const handleSubmit = (e: any) => {
    e.preventDefault();
    validateFields((err: Object, values: RegisterValues) => {
      if (!err) {
        registerUser({ email: values.email, siteID: values.siteId });
      }
    });
  };

  return (
    <div className={PasswordConfirmationStyles.HomeContainer}>
      <DisclaimerFooter className={PasswordConfirmationStyles.disclaimer} />
      <img
        alt="Sibel Logo"
        src={Logo}
        className={PasswordConfirmationStyles.logo}
      />
      <Form onSubmit={handleSubmit} className={PasswordConfirmationStyles.form}>
        <h3 className={PasswordConfirmationStyles.title}>Account Request Submitted</h3>
        <span className={PasswordConfirmationStyles.text}>
          You will receive an email with an initial password after your admin
          approves your request.
        </span>
        {/* <Form.Item className={PasswordConfirmationStyles.fieldItem}>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input a password" }]
          })(
            <Input
              name="siteId"
              placeholder="Password"
              type="password"
              className={PasswordConfirmationStyles.input}
              prefix={
                <Icon type="lock" className={PasswordConfirmationStyles.icon} />
              }
            />
          )}
        </Form.Item>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className={PasswordConfirmationStyles.loginButton}
          disabled={hasErrors(getFieldsError())}
        >
          NEXT
        </Button> */}
      </Form>
    </div>
  );
};

export default Form.create({ name: "PasswordRegistrationForm" })(
  PasswordConfirmation
);
