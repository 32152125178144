import { take, put, call } from "redux-saga/effects";
import { notification } from "antd";
import { createAction } from "redux-actions";
import sibelAPI from "../apis";

export const GET_ADMIN_DASHBOARD_CLINICAL_USERS =
  "GET_ADMIN_DASHBOARD_CLINICAL_USERS";

export const FINISH_ADMIN_DASHBOARD_CLINICAL_USERS =
  "FINISH_ADMIN_DASHBOARD_CLINICAL_USERS";

export const getAdminDashboardClinicalUsers = createAction(
  "GET_ADMIN_DASHBOARD_CLINICAL_USERS"
);

export const finishAdminDashboardClinicalUsers = createAction(
  "FINISH_ADMIN_DASHBOARD_CLINICAL_USERS"
);

export const setSelectedRows = createAction(
  "SET_SELECTED_ROWS"
);

export const clearSelectedRows = createAction(
  "CLEAR_SELECTED_ROWS"
);

export const SET_SELECTED_ROWS = "SET_SELECTED_ROWS";
export const CLEAR_SELECTED_ROWS = "CLEAR_SELECTED_ROWS"

export const BEGIN_AUTHORIZE_USER = "BEGIN_AUTHORIZE_USER";
export const FINISH_AUTHORIZE_USER = "FINISH_AUTHORIZE_USER";

export const beginAuthorizeUser = createAction("BEGIN_AUTHORIZE_USER");
export const finishAuthorizeUser = createAction("FINISH_AUTHORIZE_USER");

export function* watchGetAdminDashboardClinicalUsers() {
  while (true) {
    const { payload } = yield take(GET_ADMIN_DASHBOARD_CLINICAL_USERS);
    yield call(fetchAdminDashboardClinicalUsers, payload);
  }
}

export function* watchAuthorizeUsers() {
  while (true) {
    const { payload } = yield take(BEGIN_AUTHORIZE_USER);
    yield call(authorizeClinicalUsers, payload);
  }
}

export function* authorizeClinicalUsers(payload: any) {
  try {
    const response = yield call(
      sibelAPI,
      "adminDashboardAuthenticateClinicalUsers",
      payload
    );
    notification.open({
      type: "success",
      message: "Clinical User authorized"
    });
    yield put(getAdminDashboardClinicalUsers());
    yield put(clearSelectedRows());
    return response;
  } catch (error) {
    notification.open({
      type: "error",
      message: error.message
    });
  }
}

export function* fetchAdminDashboardClinicalUsers(payload: any) {
  const response = yield call(
    sibelAPI,
    "adminDashboardGetClinicalUsers",
    payload
  );
  yield put(finishAdminDashboardClinicalUsers(response.data));
}
