import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HomeStyles from './Home.module.css';
import DisclaimerFooter from '../DisclaimerFooter/DisclamerFooter';
import Logo from '../../assets/Logo.svg';
import { Input, Icon, Button, Form } from 'antd';

interface LoginProps {
  form: {
    getFieldsError: Function;
  };
  beginLoginUser: Function;
  signUpUser: Function;
  loginFailed: boolean;
  pathname: string;
}

function hasErrors(fieldsError: any) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const Home: React.FC<LoginProps> = ({ form: { getFieldsError }, beginLoginUser, signUpUser, loginFailed }) => {
  const [login, setLogin] = useState({
    username: '',
    password: '',
  });
  const [defaultPasswordType, togglePasswordType] = useState('password');
  const { username, password } = login;

  const changeLoginDetails = (e: any) => {
    setLogin({ ...login, [e.target.name]: e.target.value.trim() });
  };

  const toggleShowPassword = () => {
    if (defaultPasswordType === 'password') {
      togglePasswordType('text');
    } else {
      togglePasswordType('password');
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    beginLoginUser({ email: username.toLowerCase(), password });
  };

  return (
    <div className={HomeStyles.HomeContainer}>
      <DisclaimerFooter className={HomeStyles.disclaimer} />
      <img src={Logo} className={HomeStyles.logo} alt="Sibel Logo" />
      <Form onSubmit={handleSubmit} className={HomeStyles.form}>
        <Form.Item validateStatus={loginFailed ? 'error' : 'validating'}>
          <Input
            name="username"
            onChange={changeLoginDetails}
            placeholder="USERNAME"
            prefix={<Icon type="user" className={HomeStyles.homeIcon} />}
            className={HomeStyles.input}
            size="large"
            value={username}
          />
        </Form.Item>
        <Form.Item
          validateStatus={loginFailed ? 'error' : 'validating'}
          help={loginFailed ? 'Invalid username or password' : null}
        >
          <Input
            name="password"
            onChange={changeLoginDetails}
            placeholder="PASSWORD"
            prefix={<Icon type="lock" className={HomeStyles.homeIcon} />}
            type={defaultPasswordType}
            className={HomeStyles.input}
            size="large"
            value={password}
            suffix={
              <Button onClick={toggleShowPassword} className={HomeStyles.eyePassword}>
                {defaultPasswordType === 'password' ? (
                  <Icon type="eye-invisible" className={HomeStyles.homeIcon} />
                ) : (
                  <Icon type="eye" className={HomeStyles.homeIcon} />
                )}
              </Button>
            }
          />
        </Form.Item>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className={HomeStyles.loginButton}
          disabled={hasErrors(getFieldsError())}
        >
          Log In
        </Button>
        <Link to="/register">
          <Button name="register" type="default" size="large" className={HomeStyles.loginButton}>
            Register
          </Button>
        </Link>
      </Form>
    </div>
  );
};

export default Form.create({ name: 'LoginForm' })(Home);
