import { handleActions } from 'redux-actions';
import { GET_SESSIONS, FINISH_GET_SESSIONS } from '../sagas/sessions';

export const defaultState = {
  data: [],
  loading: false,
};

const reducer = handleActions(
  {
    GET_SESSIONS: (state: any, action: any) => ({
      ...state,
      loading: true,
    }),
    FINISH_GET_SESSIONS: (state: any, action: any) => ({
      loading: false,
      data: action.payload,
    }),
  },
  defaultState,
);

export default reducer;
