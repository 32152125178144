import React from "react";
import { Route, Switch, Redirect } from "react-router";
import Home from "./components/Home/HomeContainer";
import Dashboard from "./components/Dashboard/DashboardContainer";
import Help from "./components/Help/HelpContainer";
import Sessions from "./components/Sessions/SessionContainer";
import Admin from "./components/Admin/AdminContainer";
import MainLayout from "./components/Layout/Layout";
import AccountContainer from "./components/Account/AccountContainer";
import ResetPassword from "./components/ResetPassword/ResetPasswordContainer";
import RegisterContainer from "./components/Register/RegisterContainer";
import PasswordConfirmationContainer from "./components/PasswordConfirmation/PasswordConfirmation";
import SecretResetContainer from "./components/SecretReset/SecretResetContainer";
import { getSessionStorage } from "./sagas/utils";
import Account from "./components/Account/Account";

export function isUserLoggedIn() {
	const token = getSessionStorage("token");
	if (!token) {
		return false;
	}
	return true;
}

const routes = (
	<React.Fragment>
		<Route exact path="/" component={Home} />
		<MainLayout />
		<Switch>
			<Route
				path="/dashboard"
				render={() =>
					isUserLoggedIn() ? <Dashboard /> : <Redirect to="/" />
				}
			/>
			<Route
				path="/sessions"
				render={() =>
					isUserLoggedIn() ? <Sessions /> : <Redirect to="/" />
				}
			/>
			<Route
				path="/admin"
				render={() =>
					isUserLoggedIn() ? <Admin /> : <Redirect to="/" />
				}
			/>
			<Route path="/reset-password" component={ResetPassword} />
			<Route path="/register" component={RegisterContainer} />
			<Route
				path="/confirmation"
				component={PasswordConfirmationContainer}
			/>
			<Route path="/secret" component={SecretResetContainer} />
			<Route
				path="/account"
				render={() =>
					isUserLoggedIn() ? (
						<AccountContainer />
					) : (
						<Redirect to="/" />
					)
				}
			/>
			<Route
				path="/logout"
				render={props => {
					sessionStorage.clear();
					return (
						<Redirect
							to={{
								pathname: "/"
							}}
						/>
					);
				}}
			/>
		</Switch>
	</React.Fragment>
);

export default routes;
