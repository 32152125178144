import { take, put, call } from 'redux-saga/effects';
import { createAction } from 'redux-actions';
import sibelAPI from '../apis';
import { getSessionStorage } from './utils';

export const GET_DASHBOARD_STATS_CLINICAL_INVESTIGATOR = 'GET_DASHBOARD_STATS_CLINICAL_INVESTIGATOR';

export const FINISH_DASHBOARD_STATS_CLINICAL_INVESTIGATOR = 'FINISH_DASHBOARD_STATS_CLINICAL_INVESTIGATOR';

export const FINISH_DASHBOARD_STATS_PRIVILEGED_USER = 'FINISH_DASHBOARD_STATS_PRIVILEGED_USER';

export const getDashboardStatsClinicalInvestigator = createAction('GET_DASHBOARD_STATS_CLINICAL_INVESTIGATOR');

export const finishDashboardStatsPrivilegedUser = createAction('FINISH_DASHBOARD_STATS_PRIVILEGED_USER');
export const finishDashboardStatsClinicalInvestigator = createAction('FINISH_DASHBOARD_STATS_CLINICAL_INVESTIGATOR');

// Total Stats

export const GET_TOTAL_DATA_CLINICAL_INVESTIGATOR = 'GET_TOTAL_DATA_CLINICAL_INVESTIGATOR';

export const FINISH_TOTAL_DATA_CLINICAL_INVESTIGATOR = 'FINISH_TOTAL_DATA_CLINICAL_INVESTIGATOR';

export const getTotalDataClinicalInvestigator = createAction('GET_TOTAL_DATA_CLINICAL_INVESTIGATOR');

export const finishTotalDataClinicalInvestigator = createAction('FINISH_TOTAL_DATA_CLINICAL_INVESTIGATOR');

// Compliance Score

export const GET_COMPLIANCE_SCORE = 'GET_COMPLIANCE_SCORE';
export const FINISH_COMPLIANCE_SCORE = 'FINISH_COMPLIANCE_SCORE';

export const getComplianceScore = createAction(GET_COMPLIANCE_SCORE);
export const finishComplianceScore = createAction(FINISH_COMPLIANCE_SCORE);

export function* watchGetDasbboardStatsClinicalInvestigator() {
	while (true) {
		const payload = yield take(GET_DASHBOARD_STATS_CLINICAL_INVESTIGATOR);
		yield call(fetchDashboardStats, payload);
	}
}

export function* watchGetTotalDataClinicalInvestigator() {
	while (true) {
		const { payload } = yield take(GET_TOTAL_DATA_CLINICAL_INVESTIGATOR);
		yield call(fetchTotalStatsDashboard, payload);
	}
}

export function* watchGetComplianceScore() {
	while (true) {
		const { payload } = yield take(GET_COMPLIANCE_SCORE);
		yield call(fetchComplianceScore, payload);
	}
}

export function* fetchComplianceScore(payload: any) {
	try {
		const response = yield call(sibelAPI, 'getComplianceScore', payload);
		yield put(finishComplianceScore(response.data));
	} catch (error) {
		console.log(error);
	}
}

export function* fetchTotalStatsDashboard(payload: { email: string; time_span: number; userType: string }) {
	const emailAddress = payload.email ? payload.email : getSessionStorage('email');
	const user = payload.userType ? payload.userType : getSessionStorage('userType');

	if (user !== 'privileged_user') {
		try {
			const response = yield call(sibelAPI, 'clinicalInvestigatorTotalStats', {
				...payload,
				email: emailAddress,
			});
			if (response && response.data) {
				yield put(finishTotalDataClinicalInvestigator(response.data));
			}
		} catch (error) {
			console.log(error);
		}
	} else {
		try {
			const response = yield call(sibelAPI, 'privilegedUserTotalStats', {
				...payload,
				email: emailAddress,
			});
			yield put(finishTotalDataClinicalInvestigator(response.data));
		} catch (error) {
			console.log(error);
		}
	}
}

export function* fetchDashboardStats({ email, userType }: { email: string; userType: string }) {
	const emailAddress = email ? email : getSessionStorage('email');
	const user = userType ? userType : getSessionStorage('userType');

	if (user !== 'privileged_user') {
		try {
			const response = yield call(sibelAPI, 'clinicalInvestigatorDashboardStats', emailAddress);
			yield put(finishDashboardStatsClinicalInvestigator(response.data));
		} catch (error) {
			console.log(error);
		}
	} else {
		try {
			const response = yield call(sibelAPI, 'privilegedUserDashboardStats', emailAddress);
			yield put(finishDashboardStatsPrivilegedUser(response.data));
		} catch (error) {
			console.log(error);
		}
	}
}
