import { createAction } from "redux-actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Home from "./Home";

export const BEGIN_LOGIN_USER = "BEGIN_LOGIN_USER";
export const FINISH_LOGIN = "FINISH_LOGIN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const UPDATE_TOKEN = "UPDATE_TOKEN";

// Sign Up User

export const BEGIN_SIGN_UP_USER = "BEGIN_SIGN_UP_USER";
export const FINISH_SIGN_UP_USER = "FINISH_SIGN_UP_USER";

export const beginSignUpUser = createAction("BEGIN_SIGN_UP_USER");

export const loginFailed = createAction(LOGIN_FAILED);
export const loginUser = createAction(BEGIN_LOGIN_USER);
export const updateToken = createAction(UPDATE_TOKEN);

function mapState(state: any) {
	return {
		userId: state.login.userId,
		loginFailed: state.login.loginFailed,
		pathname: state.router.location.pathname
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		beginLoginUser: bindActionCreators(loginUser, dispatch),
		signUpUser: bindActionCreators(beginSignUpUser, dispatch)
	};
}

export default withRouter(connect(
	mapState,
	mapDispatchToProps
)(Home) as any);
