import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import Admin from "./Admin";
import {
  getAdminDashboardClinicalUsers,
  setSelectedRows,
  clearSelectedRows,
  beginAuthorizeUser
} from "../../sagas/admin";

function mapState(state: any) {
  return {
    clinicalUsers: state.admin.clinicalUsers,
    adminLoadingUsers: state.admin.loading,
    selectedRows: state.admin.selectedRows
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchAdminDashboardClinicalUsers: bindActionCreators(
      getAdminDashboardClinicalUsers,
      dispatch
    ),
    authorizeUsers: bindActionCreators(beginAuthorizeUser, dispatch),
    setSelectedRows: bindActionCreators(setSelectedRows, dispatch),
    clearSelectedRows: bindActionCreators(clearSelectedRows, dispatch),
  };
}

export default connect(
  mapState,
  mapDispatchToProps
)(Admin);
