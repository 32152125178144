import { take, put, call } from "redux-saga/effects";
import { notification } from "antd";
import { createAction } from "redux-actions";
import sibelAPI from "../apis";
import { getSessionStorage } from "./utils";

export const BEGIN_FETCH_RESET_PASSWORD = "BEGIN_FETCH_RESET_PASSWORD";
export const FINISH_FETCH_RESET_PASSWORD = "FINISH_FETCH_RESET_PASSWORD";

export const CLEAR_PASSWORD = "CLEAR_PASSWORD";

export const beginFetchResetPassword = createAction(
  "BEGIN_FETCH_RESET_PASSWORD"
);
export const finishFetchResetPassword = createAction(
  "FINISH_FETCH_RESET_PASSWORD"
);

export const clearPassword = createAction("CLEAR_PASSWORD");

export function* watchResetPassword() {
  while (true) {
    const { payload } = yield take(BEGIN_FETCH_RESET_PASSWORD);
    yield call(fetchAccountInformation, payload);
  }
}

export function* fetchAccountInformation(payload: any) {
  const user = getSessionStorage("userType");
  if (user !== "privileged_user") {
    try {
      const response = yield call(
        sibelAPI,
        "clinicalInvestigatorResetPassword",
        payload
      );

      if (!response) {
        notification.open({
          type: "error",
          message: "Incorrect Old Password"
        });
        return;
      }
      notification.open({
        type: "success",
        message: "Password reset"
      });
      yield put(clearPassword());
    } catch (error) {
      notification.open({
        type: "error",
        message: error.message
      });
    }
  } else {
    try {
      const response = yield call(
        sibelAPI,
        "privilegedUserResetPassword",
        payload
      );
      if (!response) {
        notification.open({
          type: "error",
          message: "Incorrect Old Password"
        });
        return;
      }
      notification.open({
        type: "success",
        message: "Password reset"
      });
      yield put(clearPassword());
    } catch (error) {
      notification.open({
        type: "error",
        message: error.message
      });
    }
  }
}
