import React, { useState } from 'react';
import { Layout, Button, Form, Input, Icon } from 'antd';
import ResetPasswordStyles from './ResetPassword.module.css';
import PageTitle from '../PageTitle/PageTitle';
import DisclaimerFooter from '../DisclaimerFooter/DisclamerFooter';
import AvatarPageTitleWrapper from '../AvatarPageTitleWrapper/AvatarPageTitleWrapper';
import Logo from '../../assets/Logo.svg';

const { Content } = Layout;

interface ResetAccountProps {
  form: {
    getFieldsError: Function;
    getFieldDecorator: Function;
    validateFields: Function;
    setFields: Function;
    setFieldsValue: Function;
    resetFields: Function;
  };
  resetPassword: Function;
  email: string;
  userType: string;
  oldPassword: string;
  newPassword: string;
}

type PasswordResetValues = {
  old_password: string;
  new_password: string;
  verifyPassword: string;
};

function hasErrors(fieldsError: any) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const ResetAccount: React.FC<ResetAccountProps> = ({
  form: { getFieldsError, getFieldDecorator, validateFields, setFields, setFieldsValue, resetFields },
  email,
  resetPassword,
  userType,
  oldPassword,
  newPassword,
}) => {
  const handleSubmit = (e: any) => {
    e.preventDefault();
    validateFields((err: Object, values: PasswordResetValues) => {
      if (!err) {
        if (values.new_password === values.old_password) {
          setFields({
            new_password: {
              errors: [new Error('New Password cannot be the same as your Old Password')],
            },
          });
          return;
        }
        if (values.new_password !== values.verifyPassword) {
          setFields({
            verifyPassword: {
              errors: [new Error('Repeat Password must match New Password')],
            },
          });
          return;
        }
        resetPassword({
          email: email,
          old_password: values.old_password,
          new_password: values.new_password,
        });

        setFieldsValue({
          email: '',
          old_password: '',
          new_password: '',
          verifyPassword: '',
        });
      }
    });
  };

  const validateNewPassword = (rule: string, value: string, callback: Function): void => {
    if (!value) {
      callback();
    } else if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g.test(value)) {
      callback();
    } else {
      callback(
        'Use 8 or more characters with a mix of uppercase and lowercase letters, numbers & allowed symbols(#?!@$%^&*-)',
      );
    }
  };

  return (
    <React.Fragment>
      <Layout>
        <DisclaimerFooter className={ResetPasswordStyles.disclaimer} />
        <div className={ResetPasswordStyles.container}>
          <AvatarPageTitleWrapper email={email} pageTitle={'Password Reset'} user={userType} />
          <Content className={ResetPasswordStyles.passwordContent}>
            <img src={Logo} className={ResetPasswordStyles.logo} alt="Sibel Logo" />
            <Form onSubmit={handleSubmit} className={'passwordChangeForm'}>
              <div className={ResetPasswordStyles.formContainer}>
                <Form.Item className={ResetPasswordStyles.fieldItem}>
                  {getFieldDecorator('old_password', {
                    rules: [{ required: true, message: 'Old Password is required' }],
                  })(
                    <Input.Password
                      name="old_password"
                      placeholder="Old Password"
                      value={oldPassword}
                      prefix={<Icon type="lock" className={ResetPasswordStyles.homeIcon} />}
                    />,
                  )}
                </Form.Item>
                <Form.Item className={ResetPasswordStyles.fieldItem}>
                  {getFieldDecorator('new_password', {
                    rules: [
                      { required: true, message: 'New Password is required' },
                      { validator: validateNewPassword },
                    ],
                  })(
                    <Input.Password
                      name="new_password"
                      placeholder="New Password"
                      value={newPassword}
                      prefix={<Icon type="lock" className={ResetPasswordStyles.homeIcon} />}
                    />,
                  )}
                </Form.Item>
                <Form.Item className={ResetPasswordStyles.fieldItem}>
                  {getFieldDecorator('verifyPassword', {
                    rules: [{ required: true, message: 'Repeat Password is required' }],
                  })(
                    <Input.Password
                      name="verifyPassword"
                      placeholder="Repeat New Password"
                      prefix={<Icon type="lock" className={ResetPasswordStyles.homeIcon} />}
                    />,
                  )}
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={ResetPasswordStyles.submitButton}
                  disabled={hasErrors(getFieldsError())}
                >
                  Reset Password
                </Button>
              </div>
            </Form>
          </Content>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Form.create({ name: 'ResetForm' })(ResetAccount);
