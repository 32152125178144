import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Modal, Divider, Spin } from "antd";
import SessionDetailStyles from "./SessionDetail.module.css";
import DisclaimerFooter from "../DisclaimerFooter/DisclamerFooter";

interface SessionDetailProps {
  match?: any;
  history?: any;
  fetchSessionDetails: Function;
  email: string;
  userType: string;
  sessionDetailData: any;
  sessionDetailsLoading: boolean;
  vitals: any;
}

const SessionDetail: React.FC<SessionDetailProps> = ({
  match,
  history,
  fetchSessionDetails,
  email,
  userType,
  sessionDetailData,
  sessionDetailsLoading,
  vitals
}) => {
  const [visible, ,] = useState(true);

  function humanFileSize(bytes: number, si: boolean) {
    if (!bytes) {
      return "-";
    }
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + " bytes";
    }
    var units = si
      ? ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    var u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return Math.round(bytes * 100) / 100 + " " + units[u];
  }

  function secondsToHms(d: number) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    const display = (
      <React.Fragment>
        {h > 0 && (
          <React.Fragment>
            <span>{h}h</span>
          </React.Fragment>
        )}
        {m > 0 && (
          <React.Fragment>
            <span>{m}m</span>
          </React.Fragment>
        )}
        {s > 0 && (
          <React.Fragment>
            <span>{s}s</span>
          </React.Fragment>
        )}
        {}
      </React.Fragment>
    );
    return display;
  }
  const formatDate = (date: string) => {
    if (!date) {
      return "";
    }
    return dayjs(date.split(" ").join("")).format("YYYY.MM.DD");
  };

  const formatDateTime = (date: string) => {
    if (!date) {
      return "";
    }
    return dayjs(date.split(" ").join("")).format("YYYY-MM-DD HH:mm:ss");
  };

  useEffect(() => {
    fetchSessionDetails({ email, SessionID: match.params.sessionId, userType });
  }, [email, fetchSessionDetails, match.params.sessionId, userType]);

  const purifyInput = (value: any) => (value ? value : "-");
  return (
    <React.Fragment>
      {sessionDetailsLoading ? (
        <Spin />
      ) : (
        <Modal
          visible={visible}
          onCancel={() => history.push("/sessions")}
          footer={null}
          width={1200}
        >
          <div>
            <DisclaimerFooter className={SessionDetailStyles.disclaimer} />
            <h3>SESSION ID {match.params.sessionId}</h3>
            <div className={SessionDetailStyles.tableContainer}>
              <table className={SessionDetailStyles.table}>
                <h4>VITALS OVERVIEW</h4>
                <tbody>
                  <thead className={SessionDetailStyles.heartRate}>
                    Heart Rate
                  </thead>
                  <tr>
                    <td>Average</td>
                    <td>{purifyInput(vitals.HRAvg)}</td>
                  </tr>
                  <tr>
                    <td>Max</td>
                    <td>{purifyInput(vitals.HRMax)}</td>
                  </tr>
                  <tr>
                    <td>Min</td>
                    <td>{purifyInput(vitals.HRMin)}</td>
                  </tr>
                  <Divider />
                  <thead className={SessionDetailStyles.respRate}>
                    Respiratory Rate
                  </thead>
                  <tr>
                    <td>Average</td>
                    <td>{purifyInput(vitals.RRAvg)}</td>
                  </tr>
                  <tr>
                    <td>Max</td>
                    <td>{purifyInput(vitals.RRMax)}</td>
                  </tr>
                  <tr>
                    <td>Min</td>
                    <td>{purifyInput(vitals.RRMin)}</td>
                  </tr>
                  <Divider />
                  <thead className={SessionDetailStyles.sp2}>SPO2</thead>
                  <tr>
                    <td>Average</td>
                    <td>{purifyInput(vitals.SP02Avg)}</td>
                  </tr>
                  <tr>
                    <td>Max</td>
                    <td>{purifyInput(vitals.SP02Max)}</td>
                  </tr>
                  <tr>
                    <td>Min</td>
                    <td>{purifyInput(vitals.SP02Min)}</td>
                  </tr>
                </tbody>
              </table>
              <table className={SessionDetailStyles.table}>
                <h4>SESSION DETAILS</h4>
                <tbody>
                  <tr>
                    <td>Subject ID</td>
                    <td>{purifyInput(sessionDetailData.SubjectID)}</td>
                  </tr>
                  <tr>
                    <td>Subject Status</td>
                    <td>{purifyInput(sessionDetailData.SubjectStatus)}</td>
                  </tr>
                  <tr>
                    <td>Recruitment Date</td>
                    <td>
                      {formatDate(
                        purifyInput(sessionDetailData.RecruitmentDate)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Completion Date</td>
                    <td>
                      {formatDate(
                        purifyInput(sessionDetailData.CompletionDate)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>{purifyInput(sessionDetailData.Country)}</td>
                  </tr>
                  <tr>
                    <td>Clinical Site</td>
                    <td>{purifyInput(sessionDetailData.SiteID)}</td>
                  </tr>
                  <tr>
                    <td>Session Start</td>
                    <td>{formatDateTime(sessionDetailData.SessionStart)}</td>
                  </tr>
                  <tr>
                    <td>Session End</td>
                    <td>{formatDateTime(sessionDetailData.SessionEnd)}</td>
                  </tr>
                  <tr>
                    <td>Total Time of Session</td>
                    <td>
                      {secondsToHms(sessionDetailData.TotalTimeOfSession)}
                    </td>
                  </tr>
                  <tr>
                    <td>ANNE Chest File Size</td>
                    <td>
                      {humanFileSize(sessionDetailData.ANNEChestDataSize, true)}
                    </td>
                  </tr>
                  <tr>
                    <td>ANNE Limb File Size</td>
                    <td>
                      {humanFileSize(sessionDetailData.ANNELimbDataSize, true)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default SessionDetail;
