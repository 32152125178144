import { take, put, call } from "redux-saga/effects";
import { notification } from "antd";
import { createAction } from "redux-actions";
import sibelAPI from "../apis";
import { getSessionStorage } from "./utils";

export const BEGIN_SUBMIT_HELP_INFORMATION = "BEGIN_SUBMIT_HELP_INFORMATION";
export const FINISH_SUBMIT_HELP_INFORMATION = "FINISH_SUBMIT_HELP_INFORMATION";
export const ERROR_SUBMIT_HELP_INFORMATION = "ERROR_SUBMIT_HELP_INFORMATION";

export const submitHelpInformation = createAction(
  "BEGIN_SUBMIT_HELP_INFORMATION"
);

export const finishSubmitHelpInformation = createAction(
  "FINISH_SUBMIT_HELP_INFORMATION"
);

export const errorSubmitHelpInformation = createAction(
  "ERROR_SUBMIT_HELP_INFORMATION"
);

export function* watchSubmitHelpInformation() {
  while (true) {
    const { payload } = yield take(BEGIN_SUBMIT_HELP_INFORMATION);
    yield call(fetchSubmitHelpInformation, payload);
  }
}

type HelpInformation = {
  name: string;
  email: string;
  subject: string;
  message: string;
};

export function* fetchSubmitHelpInformation(payload: HelpInformation) {
  const user = getSessionStorage("userType");

  if (user !== "privileged_user") {
    try {
      const response = yield call(
        sibelAPI,
        "clinicalInvestigatorSubmitHelp",
        payload
      );
      notification.open({
        type: "success",
        message: "Message sent!"
      });
      yield put(finishSubmitHelpInformation(response.data));
    } catch (error) {
      notification.open({
        type: "error",
        message: error.message
      });
      yield put(errorSubmitHelpInformation());
    }
  } else {
    try {
      const response = yield call(
        sibelAPI,
        "privilegedUserSubmitHelp",
        payload
      );
      notification.open({
        type: "success",
        message: "Message sent!"
      });
      yield put(finishSubmitHelpInformation(response.data));
    } catch (error) {
      notification.open({
        type: "error",
        message: error.message
      });
      yield put(errorSubmitHelpInformation());
    }
  }
}
