import { handleActions } from 'redux-actions';
import { BEGIN_FETCH_ACCOUNT_INFORMATION, FINISH_FETCH_ACCOUNT_INFORMATION } from '../sagas/account';
import { CLEAR_PASSWORD } from '../sagas/resetPassword';

export const defaultState = {
  siteId: '',
  loading: false,
  userLevel: '',
  username: '',
  oldPassword: '',
  newPassword: '',
};

const reducer = handleActions(
  {
    BEGIN_FETCH_ACCOUNT_INFORMATION: (state: any, action: any) => ({
      ...state,
      loading: true,
    }),
    FINISH_FETCH_ACCOUNT_INFORMATION: (state: any, action: any) => ({
      loading: false,
      siteId: action.payload.SiteID,
      userLevel: action.payload.UserLevel,
      username: action.payload.Username,
    }),
    CLEAR_PASSWORD: (state: any, action: any) => ({
      ...state,
      oldPassword: '',
      newPassword: '',
    }),
  },
  defaultState,
);

export default reducer;
