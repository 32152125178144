import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import SecretReset from "./SecretReset";
import { beginSecretReset } from "../../sagas/secretReset";

function mapState(state: any) {
  return {
    emailAddress: state.login.emailAddress
  };
}

function mapDispatch(dispatch: Dispatch) {
  return {
    secretReset: bindActionCreators(beginSecretReset, dispatch)
  };
}

export default connect(
  mapState,
  mapDispatch
)(SecretReset);
