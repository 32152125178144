import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import dayjs from "dayjs";
import Dashboard from "./Dashboard";
import {
  getDashboardStatsClinicalInvestigator,
  getTotalDataClinicalInvestigator,
  getComplianceScore
} from "../../sagas/dashboard";
import { getSessionStorage } from "../../sagas/utils";

function mapState(state: any) {
  return {
    email: state.login.emailAddress || getSessionStorage("email"),
    userType: state.login.userType || getSessionStorage("userType"),
    activeDevices: state.dashboard.activeDevices,
    totalData: state.dashboard.totalData,
    complianceScore: state.dashboard.complianceScore,
    countries: state.dashboard.countries,
    clinicalSites: state.dashboard.clinicalSites,
    graphData: transformGraphData(state.dashboard.graphData)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchDashboardStatsClinicalInvestigator: bindActionCreators(
      getDashboardStatsClinicalInvestigator,
      dispatch
    ),
    fetchTotalDataClinicalInvestigator: bindActionCreators(
      getTotalDataClinicalInvestigator,
      dispatch
    ),
    fetchComplianceScore: bindActionCreators(getComplianceScore, dispatch)
  };
}

type GraphDataCollected = {
  DATE: string;
  DataCollected: number;
};

function transformGraphData(state: any) {
  var dayCount = state.length;
  var offset = dayjs().utcOffset();
  if (dayCount === 12) {
    return state.map((data: GraphDataCollected, index: number) => {
      return {
        date: dayjs()
          .subtract(dayCount - index - 1, "month")
          .format("YY/MM"),
        GB: data.DataCollected ? bytesToGB(data.DataCollected) : 0
      };
    });
  } else {
    return state.map((data: Array<GraphDataCollected>, index: number) => {
      return {
        date: dayjs()
          .subtract(dayCount - index - 1, "day")
          .subtract(offset, "minute")
          .format("MM/DD"),
        GB: data.length ? bytesToGB(data[0].DataCollected) : 0
      };
    });
  }
}

function bytesToGB(bytes: number) {
  let gb = bytes / 1000 / 1000;
  return Math.round(gb * 100) / 100;
}

export default connect(
  mapState,
  mapDispatchToProps
)(Dashboard);
