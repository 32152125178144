import React from "react";
import SecretResetStyles from "./SecretReset.module.css";
import DisclaimerFooter from "../DisclaimerFooter/DisclamerFooter";
import Logo from "../../assets/Logo.svg";
import { Input, Icon, Button, Form } from "antd";

interface LoginProps {
  form: {
    getFieldsError: Function;
    getFieldDecorator: Function;
    validateFields: Function;
  };
  emailAddress: string;
  secretReset: Function;
}

type RegisterValues = {
  siteId: string;
  email: string;
};

function hasErrors(fieldsError: any) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const SecretReset: React.FC<LoginProps> = ({
  form: { getFieldsError, getFieldDecorator, validateFields },
  secretReset,
  emailAddress
}) => {
  const handleSubmit = (e: any) => {
    e.preventDefault();
    validateFields((err: Object, values: RegisterValues) => {
      if (!err) {
        secretReset({ password: values.email, siteID: values.siteId });
      }
    });
  };

  const validateSideId = (
    rule: string,
    value: string,
    callback: Function
  ): void => {
    if (/\bM02-[A-Za-z]{2}1[0-9]{4}\b/g.test(value)) {
      callback();
    } else {
      callback("Please enter a correctly formatted site ID.");
    }
  };

  return (
    <div className={SecretResetStyles.HomeContainer}>
      <DisclaimerFooter className={SecretResetStyles.disclaimer} />
      <img src={Logo} className={SecretResetStyles.logo} alt="Sibel Logo" />
      <Form onSubmit={handleSubmit} className={SecretResetStyles.form}>
        <Form.Item className={SecretResetStyles.fieldItem}>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input a password" }]
          })(
            <Input
              name="password"
              placeholder="Password"
              type="password"
              className={SecretResetStyles.input}
              prefix={<Icon type="lock" className={SecretResetStyles.icon} />}
            />
          )}
        </Form.Item>
        <Form.Item className={SecretResetStyles.fieldItem}>
          {getFieldDecorator("siteId", {
            rules: [
              {
                required: true,
                validator: validateSideId
              }
            ]
          })(
            <Input
              name="siteId"
              placeholder="CLINICAL SITE ID (M02-XXXXXXX)"
              className={SecretResetStyles.input}
              prefix={
                <Icon type="environment" className={SecretResetStyles.icon} />
              }
            />
          )}
        </Form.Item>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className={SecretResetStyles.loginButton}
          disabled={hasErrors(getFieldsError())}
        >
          Register
        </Button>
      </Form>
    </div>
  );
};

export default Form.create({ name: "ResetForm" })(SecretReset);
