import { take, put, call } from "redux-saga/effects";
import { notification } from "antd";
import * as actions from "../components/Home/HomeContainer";
import { history } from "../configureStore";
import { createAction } from "redux-actions";
import { setSessionStorage } from "./utils";
import sibelAPI from "../apis";

export const finishLogin = createAction("FINISH_LOGIN");

export function* watchLoginUser() {
	while (true) {
		const { payload } = yield take(actions.BEGIN_LOGIN_USER);
		yield call(loginUser, payload);
	}
}

export function* watchSignUpUser() {
	while (true) {
		const { payload } = yield take(actions.BEGIN_SIGN_UP_USER);
		yield call(signUpUser, payload);
	}
}

export function* signUpUser(payload: { email: string; password: string }) {
	const response = yield call(
		sibelAPI,
		"signUpClinicalInvestigator",
		payload
	);
	if (response && response.status === 200) {
		yield put(finishLogin({ email: payload.email, ...response.data }));
		setSessionStorage("token", response.data.id_token);
		setSessionStorage("email", payload.email);
		setSessionStorage("refreshToken", response.data.refresh_token);
		setSessionStorage("userType", response.data.user);
		setSessionStorage(
			"expiresIn",
			(Date.now() + response.data.expires_in * 1000).toString()
		);
		yield history.push("/dashboard");
	}
}

export function* loginUser(payload: { email: string; password: string }) {
	const response = yield call(sibelAPI, "signInUser", payload);
	if (response && response.status === 200) {
		yield put(finishLogin({ email: payload.email, ...response.data }));
		setSessionStorage("token", response.data.id_token);
		setSessionStorage("email", payload.email);
		setSessionStorage("refreshToken", response.data.refresh_token);
		setSessionStorage("userType", response.data.user);
		setSessionStorage(
			"expiresIn",
			(Date.now() + response.data.expires_in * 1000).toString()
		);
		yield history.push("/dashboard");
	} else {
		notification.open({
			type: "error",
			message: "Invalid username or password."
		});
	}
}
