import React from "react";
import { History } from "history";
import { Redirect } from "react-router";
import IdleTimer from "react-idle-timer";
import { ConnectedRouter } from "connected-react-router";
import routes from "./routes";

interface RootProps {
	history: History;
}

const Root = ({ history }: RootProps) => {
	const onIdle = () => history.push("/logout");
	return (
		<React.Fragment>
			<IdleTimer
				element={document}
				onIdle={onIdle}
				timeout={1000 * 60 * 30}
			/>
			<ConnectedRouter history={history}>{routes}</ConnectedRouter>;
		</React.Fragment>
	);
};

export default Root;
