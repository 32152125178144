import { handleActions } from 'redux-actions';
import get from 'lodash.get';
import {
  FINISH_DASHBOARD_STATS_CLINICAL_INVESTIGATOR,
  GET_DASHBOARD_STATS_CLINICAL_INVESTIGATOR,
  FINISH_COMPLIANCE_SCORE,
  FINISH_TOTAL_DATA_CLINICAL_INVESTIGATOR,
  FINISH_DASHBOARD_STATS_PRIVILEGED_USER,
} from '../sagas/dashboard';

export const defaultState = {
  activeDevices: 0,
  totalData: 0,
  complianceScore: 0,
  loading: false,
  countries: [],
  clinicalSites: [],
  graphData: [],
};

const reducer = handleActions(
  {
    GET_DASHBOARD_STATS_CLINICAL_INVESTIGATOR: (state: any, action: any) => ({
      ...state,
      loading: true,
    }),
    FINISH_DASHBOARD_STATS_CLINICAL_INVESTIGATOR: (state: any, action: any) => ({
      ...state,
      activeDevices: get(action.payload, 'ActiveDevices[0]', 0),
      totalData: get(action.payload, 'TotalData[0]', 0),
      complianceScore: get(action.payload, 'ComplianceScore[0]', 0),
      countries: action.payload.Countries,
      clinicalSites: action.payload.ClinicalSites,
    }),
    FINISH_DASHBOARD_STATS_PRIVILEGED_USER: (state: any, action: any) => ({
      ...state,
      activeDevices: get(action.payload, 'ActiveDevices[0]', 0),
      totalData: get(action.payload, 'TotalData[0]', 0),
      countries: action.payload.Countries,
      clinicalSites: action.payload.ClinicalSites,
    }),
    FINISH_TOTAL_DATA_CLINICAL_INVESTIGATOR: (state: any, action: any) => ({
      ...state,
      graphData: action.payload,
    }),
    FINISH_COMPLIANCE_SCORE: (state: any, action: any) => ({
      ...state,
      complianceScore: action.payload,
    }),
  },
  defaultState,
);

export default reducer;
