import { handleActions } from 'redux-actions';
import {
  GET_ADMIN_DASHBOARD_CLINICAL_USERS,
  FINISH_ADMIN_DASHBOARD_CLINICAL_USERS,
  CLEAR_SELECTED_ROWS,
  SET_SELECTED_ROWS,
} from '../sagas/admin';

export const defaultState = {
  clinicalUsers: [],
  loading: false,
  selectedRows: [],
};

const reducer = handleActions(
  {
    GET_ADMIN_DASHBOARD_CLINICAL_USERS: (state: any, action: any) => ({
      ...state,
      loading: true,
    }),
    FINISH_ADMIN_DASHBOARD_CLINICAL_USERS: (state: any, action: any) => ({
      ...state,
      loading: false,
      clinicalUsers: action.payload,
    }),
    SET_SELECTED_ROWS: (state: any, action: any) => ({
      ...state,
      selectedRows: [...action.payload],
    }),
    CLEAR_SELECTED_ROWS: (state: any, action: any) => ({
      ...state,
      selectedRows: [],
    }),
  },
  defaultState,
);

export default reducer;
