import { take, put, call } from "redux-saga/effects";
import { createAction } from "redux-actions";
import sibelAPI from "../apis";
import { getSessionStorage } from "./utils";

export const GET_SESSIONS = "GET_SESSIONS";
export const FINISH_GET_SESSIONS = "FINISH_GET_SESSIONS";

export const getSessions = createAction("GET_SESSIONS");

export const finishGetSessions = createAction("FINISH_GET_SESSIONS");

export function* watchGetSessions() {
  while (true) {
    const payload = yield take(GET_SESSIONS);
    yield call(fetchGetSessions, payload);
  }
}

export function* fetchGetSessions({
  email,
  userType
}: {
  email: string;
  userType: string;
}) {
  const emailAddress = email ? email : getSessionStorage("email");
  const user = userType ? userType : getSessionStorage("userType");

  if (user !== "privileged_user") {
    try {
      const response = yield call(
        sibelAPI,
        "clinicalInvestigatorSessions",
        emailAddress
      );
      yield put(finishGetSessions(response.data));
    } catch (error) {
      console.log(error);
    }
  } else {
    try {
      const response = yield call(
        sibelAPI,
        "privilegedUserSessions",
        emailAddress
      );
      yield put(finishGetSessions(response.data));
    } catch (error) {
      console.log(error);
    }
  }
}
